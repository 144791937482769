import PropTypes from 'prop-types';
import { memo, useState, useCallback } from 'react';
// @mui
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import {
  Box, Modal, Button, Grid, Typography, Card, CardContent, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import axios, { endpoints } from 'src/utils/axios';
import { restoreTokenFromStorage } from 'src/auth/context/jwt/utils';
import ClearIcon from '@mui/icons-material/Clear';
import { Row, Col } from 'reactstrap';
import { decryptToken } from 'src/utils/common';
import { StyledSubheader } from './styles';

import NavList from './nav-list';
import { navVerticalConfig } from '../config';
//


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: "10px",
  p: 2,
};

// ----------------------------------------------------------------------



function NavSectionVertical({ data, config, sx, ...other }) {
  const [open, setOpen] = useState(false);
  const [rewardPlanExist, setRewardPlanExist] = useState(false);
  const handleClose = () => setOpen(false);
  const [rewardPlan, setRewardPlan] = useState({
    type: { title: '', value: null, info: '' },
    pointsForNewSubscription: { title: '', value: null, info: '' },
    pointsBonusForFirstShopping: { title: '', value: null, info: '' },
    referralBonus: { title: '', value: null, info: '' },
    pointsPerDollarSpent: { title: '', value: null, info: '' },
    minimumPointsRedeemTarget: { title: '', value: null, info: '' },
    minimumPointsTargetValueCents: { title: '', value: null, info: '' },
  });

  const fetchRewardPlan = () => {
    if (!rewardPlanExist) {
      const token = localStorage.getItem('token')
      const decrypted = decryptToken(token)
      const auth = {
        headers: {
          Authorization: `Bearer ${decrypted}`
        }
      };
      axios.get(endpoints.staff.rewardPlan.details, auth)
        .then((res) => {
          setRewardPlan(res.data.data);
          setRewardPlanExist(true);
        })
        .catch(error => {
          setRewardPlan({});
          console.error('Error fetching reward plan:', error);
        });
    }
  };

  let storedData;
  let retrievedStoreInfo;
  let loginDevice;
  let loginTime;
  let formattedDate;
  let formattedTime;

  if (localStorage.getItem('isStaff') === 'true') {

    // Retrieve the string from localStorage
    storedData = localStorage.getItem('storeInfo');

    // Parse the string back into an object
    retrievedStoreInfo = JSON.parse(storedData);

    // Login Device
    loginDevice = localStorage.getItem('deviceName');

    // Login Time
    loginTime = localStorage.getItem('loginTime'); // Assuming loginTime holds the timestamp value

    formattedDate = new Date(parseInt(loginTime, 10)).toLocaleDateString('en-US', { weekday: 'long', day: 'numeric', month: 'long' });
    formattedTime = new Date(parseInt(loginTime, 10)).toLocaleTimeString('en-US');
  }

  return (
    <Stack sx={sx} {...other}>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Col style={{ display: "flex", justifyContent: "end", marginBottom: "5px" }}>
            <Button color="error" variant="outlined" onClick={handleClose}>
              <ClearIcon />
            </Button>
          </Col>

          {/* Main Content */}
          <div>
            {
              Object.keys(rewardPlan).length > 0 ?

                <TableContainer>
                  <Typography variant="overline" display="block" gutterBottom fontSize={20}>
                    REWARD PLAN
                  </Typography>

                  <Table>
                    <TableHead >
                      <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell>Details</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      <TableRow>
                        <TableCell align='left'>
                          <div style={{ display: 'flex', justifyContent: 'start' }}>
                            {rewardPlan?.type?.title ?? 'N/A'}
                            <Tooltip title={rewardPlan?.type?.info} placement="right">
                              <InfoIcon style={{ marginLeft: '25px' }} />
                            </Tooltip>
                          </div>
                        </TableCell>

                        <TableCell color='primary'>
                          {rewardPlan?.type?.value ?? 'N/A'}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <div style={{ display: 'flex', justifyContent: 'start' }}>
                            {rewardPlan?.pointsForNewSubscription?.title ?? 'N/A'}
                            <Tooltip title={rewardPlan?.pointsForNewSubscription?.info ?? 'N/A'} placement="right">
                              <InfoIcon style={{ marginLeft: '25px' }} />
                            </Tooltip>
                          </div></TableCell>
                        <TableCell color='primary'>
                          {rewardPlan?.pointsForNewSubscription?.value ?? 'N/A'}
                        </TableCell>
                      </TableRow>


                      <TableRow>
                        <TableCell>
                          <div style={{ display: 'flex', justifyContent: 'start' }}>
                            {rewardPlan?.pointsBonusForFirstShopping?.title ?? 'N/A'}
                            <Tooltip title={rewardPlan?.pointsBonusForFirstShopping?.info ?? 'N/A'} placement="right">
                              <InfoIcon style={{ marginLeft: '25px' }} />
                            </Tooltip>
                          </div></TableCell>
                        <TableCell color='primary' >
                          {rewardPlan?.pointsBonusForFirstShopping?.value ?? 'N/A'}
                        </TableCell>
                      </TableRow>



                      <TableRow>
                        <TableCell>
                          <div style={{ display: 'flex', justifyContent: 'start' }}>
                            {rewardPlan?.referralBonus?.title ?? 'N/A'}
                            <Tooltip title={rewardPlan?.referralBonus?.info ?? 'N/A'} placement="right">
                              <InfoIcon style={{ marginLeft: '25px' }} />
                            </Tooltip>
                          </div></TableCell>
                        <TableCell color='primary'>
                          {rewardPlan?.referralBonus?.value ?? 'N/A'}
                        </TableCell>
                      </TableRow>


                      <TableRow>
                        <TableCell>
                          <div style={{ display: 'flex', justifyContent: 'start' }}>
                            {rewardPlan?.pointsPerDollarSpent?.title ?? 'N/A'}
                            <Tooltip title={rewardPlan?.pointsPerDollarSpent?.info ?? 'N/A'} placement="right">
                              <InfoIcon style={{ marginLeft: '25px' }} />
                            </Tooltip>
                          </div></TableCell>
                        <TableCell color='primary'>
                          {
                            rewardPlan?.pointsPerDollarSpent?.value ?? 'N/A'
                          }
                        </TableCell>
                      </TableRow>


                      <TableRow>
                        <TableCell>
                          <div style={{ display: 'flex', justifyContent: 'start' }}>
                            {rewardPlan?.minimumPointsRedeemTarget?.title ?? 'N/A'}
                            <Tooltip title={rewardPlan?.minimumPointsRedeemTarget?.info ?? 'N/A'} placement="right">
                              <InfoIcon style={{ marginLeft: '25px' }} />
                            </Tooltip>
                          </div>
                        </TableCell>
                        <TableCell color='primary'>
                          {rewardPlan?.minimumPointsRedeemTarget?.value ?? 'N/A'}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <div style={{ display: 'flex', justifyContent: 'start' }}>
                            {rewardPlan?.minimumPointsTargetValueCents?.title ?? 'N/A'}
                            <Tooltip title={rewardPlan?.minimumPointsTargetValueCents?.info ?? 'N/A'} placement="right">
                              <InfoIcon style={{ marginLeft: '25px' }} />
                            </Tooltip>
                          </div>
                        </TableCell>
                        <TableCell color='primary'>
                          {rewardPlan?.minimumPointsTargetValueCents?.value ?? 'N/A'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                :
                <p>
                  No Reward Plan found!!
                </p>
            }
          </div>
        </Box>
      </Modal>
      {
        localStorage.getItem('isStaff') === 'true'
        &&

        (
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: "80px" }}>
            <Button variant='outlined' style={{ marginLeft: "10px", marginRight: "10px" }} onClick={() => {
              fetchRewardPlan();
              setOpen(true);
            }}>
              Show Reward Plan
            </Button>
            <Grid container spacing={2} style={{ padding: "10px" }}>
              <Grid item xs={12} md={12}>
                <Card sx={{ borderRadius: '16px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}>
                  <CardContent>
                    <Typography variant="h6">Store Info:</Typography>
                    <Typography variant="body2">Name: {retrievedStoreInfo?.name}</Typography>
                    <Typography variant="body2">ID: {retrievedStoreInfo?.id}</Typography>
                    <Typography variant="body2">Address: {retrievedStoreInfo?.address?.line1}, {retrievedStoreInfo?.address?.line2}, {retrievedStoreInfo?.address?.city}, {retrievedStoreInfo?.address?.state}, {retrievedStoreInfo?.address?.country}, {retrievedStoreInfo?.address?.pinCode}</Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={12}>
                <Card sx={{ borderRadius: '16px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}>
                  <CardContent>
                    <Typography variant="h6">Login Info:</Typography>
                    <Typography variant="body2">Logged in from: {loginDevice}</Typography>
                    <Typography variant="body2">Platform: WEB</Typography>
                    <Typography variant="body2">Logged on: {formattedDate}</Typography>
                    <Typography variant="body2">Time: {formattedTime}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        )
      }
      {data.map((group, index) => (
        <Group
          key={group.subheader || index}
          subheader={group.subheader}
          items={group.items}
          config={navVerticalConfig(config)}
        />
      ))}
    </Stack>
  );
}

NavSectionVertical.propTypes = {
  config: PropTypes.object,
  data: PropTypes.array,
  sx: PropTypes.object,
};

export default memo(NavSectionVertical);

// ----------------------------------------------------------------------

function Group({ subheader, items, config }) {
  const [open, setOpen] = useState(true);

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const renderContent = items.map((list) => (
    <NavList
      key={list.title + list.path}
      data={list}
      depth={1}
      hasChild={!!list.children}
      config={config}
    />
  ));

  return (
    <List disablePadding sx={{ px: 2 }}>
      {subheader ? (
        <>
          <StyledSubheader disableGutters disableSticky config={config}>
            {subheader}
          </StyledSubheader>

          <Collapse in={open}>{renderContent}</Collapse>
        </>
      ) : (
        renderContent
      )}
    </List>
  );
}

Group.propTypes = {
  config: PropTypes.object,
  items: PropTypes.array,
  subheader: PropTypes.string,
};
