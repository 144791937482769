import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stepper, Step, StepLabel, Button, Box, Typography, TextField, Table, TableRow, TableBody, TableCell, TableHead, Chip, Grid, Divider, FormControlLabel, Checkbox, CircularProgress, FormControl, Select, InputLabel, MenuItem, InputAdornment, IconButton, Card, CardContent, } from '@mui/material';
import axios, { endpoints } from 'src/utils/axios';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { decryptToken, formatDate } from 'src/utils/common';
import ClearIcon from '@mui/icons-material/Clear';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useSnackbar } from 'src/components/snackbar';
import QrReader from 'react-qr-scanner';
import SearchIcon from '@mui/icons-material/Search';
import './sellGiftCardCSS.css'
import { restoreTokenFromStorage } from 'src/auth/context/jwt/utils';

function getSteps() {
    return ['Select Giftcards', 'Cart Details', 'Customer Details', 'Payment Confirmation', 'Sending Giftcards', 'Done'];
};

// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 900,
    bgcolor: 'background.paper',
    border: '2px solid green',
    boxShadow: 24,
    maxHeight: "95vh",
    p: 2,
    overflow: 'auto',
    borderRadius: 1
};

// // Media query for smaller viewports
// const smallViewportStyle = {
//     width: '95%',
//     maxWidth: '100%',
// };

// Adjust style based on viewport width
const responsiveStyle = window.innerWidth <= 600 ? style : style;

function getStepContent(step, giftCardData, cartItems, setCartItems, customer, setCustomer, err, setError, customerData, setCustomerData, showFields, setShowFields, step5, toast, step1, loader, creationErr, isLoading, result, setResult, scanner, setScanner) {

    // For step 1
    const handleAdd = (gc) => {
        // Check if the gift card is already in the cartItems
        const isDuplicate = cartItems.some(item => item.id === gc.id);
        if (!isDuplicate) {
            // Add the gift card to cartItems if it's not a duplicate
            setCartItems((prev) => ([...prev, gc]));
        } else {
            toast('You cannot add same giftcard twice!.', { variant: "error" })
        }
    };

    const handleRemoveChip = (gift) => {
        // Remove the selected gift card from cartItems and selectedChips
        setCartItems((prev) => prev.filter(item => item.id !== gift.id));
    };

    // For Step 2
    const calculatedValues = {};
    if (cartItems.length) {
        calculatedValues.totalAmount = cartItems.reduce((total, item) => total + item.faceValue, 0);
        calculatedValues.discount = cartItems.reduce((total, item) => total + (item.faceValue * (item.discount / 100)), 0);
        calculatedValues.payableAmount = calculatedValues.totalAmount - calculatedValues.discount;
    };

    // For step 3
    const handleCustomerIdKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default behavior of the Enter key
            fetchCustomerData();
        }
    };

    // Added Giftcards
    const addedCount = cartItems.length;

    // API Call for Customer Data Fetch
    const fetchCustomerData = async (text) => {
        try {
            // const token = localStorage.getItem('token');
            // const decrypted = decryptToken(token)
            // const auth = {
            //     headers: {
            //         Authorization: `Bearer ${decrypted}`,
            //     },
            // };

            const data = {};
            let searchType;

            // Identify the search type based on the input value
            if (customer && customer.includes('@')) {
                data.query = customer;
                searchType = true;
            } else {
                data.query = customer;
                searchType = false;
            };

            if (customer.length === 0) {
                // Identify the search type based on the input value
                if (text.includes('@')) {
                    data.query = text;
                    searchType = true;
                } else {
                    data.query = text;
                    searchType = false;
                };
            }



            // first update the device
            const response = await axios.post(endpoints.business.customer.search, data);

            if (Object.keys(response.data).length === 0) {
                const errorMsg = !searchType ? 'Invalid username, please enter a valid one!' : 'Invalid email, please enter a valid one!';
                setError((pre) => ({ ...pre, msg: errorMsg, err: true }));
                setShowFields(false);
                return;
            }
            if (response.data) {
                setCustomerData(response.data);
                setShowFields(true);
                setCustomer('');
            };
        } catch (error) {
            // Handle error
            console.error(error);
            setError((pre) => ({ ...pre, msg: error.error || error.msg }));
            toast(`${error.error}`, { variant: 'error' });
        }
    };


    const getActualGCValue = (discount, price) => {
        const discountAmount = (discount / 100) * price;
        const discountedPrice = price - discountAmount;
        return discountedPrice.toFixed(2);
    };

    const valueForStore = JSON.parse(localStorage.getItem("ps"));

    const handleScan = (data) => {
        if (data) {
            setCustomer(data.text);
            setScanner(false);
            fetchCustomerData(data.text);
        }
    }

    const handleError = (error) => {
        setError((pre) => ({ ...pre, msg: error, err: false }));
        setScanner(false);
    }


    switch (step) {
        case 0:
            return (
                <div >
                    <div>
                        <FormControl fullWidth sx={{ mb: 1, }}>
                            <InputLabel id="demo-simple-select-label">Store</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                autoFocus
                                id="demo-simple-select"
                                value={
                                    (step1[2].find(option => option.name === step1[0]?.name) ?? '')
                                }
                                label="Store"
                                onChange={(event) => {
                                    // console.log(event.target.value)
                                    step1[1](event.target.value);
                                    localStorage.setItem("ps", JSON.stringify(event.target.value));
                                }}
                                placeholder='Please select store first to proceed..'
                            >
                                {
                                    step1[2].map((storeOption, index) => (
                                        <MenuItem key={index} value={storeOption}>{storeOption.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </div>

                    <div style={{ marginLeft: "20px" }}>
                        {
                            cartItems.length > 0 &&
                            <Typography variant="subtitle1" justifyContent='flex-end'>
                                {addedCount} Giftcard Added to cart.
                            </Typography>
                        }
                        {cartItems.length > 0 && (
                            <Box sx={{ display: 'flex', gap: 1, mt: 0.5, mb: 0.5, overflowX: 'auto', maxHeight: 50, maxWidth: 650 }}>
                                {cartItems.map((chip, index) => (
                                    <Chip
                                        key={index}
                                        label={chip.faceValue}
                                        onDelete={() => handleRemoveChip(chip)}
                                        color="primary"
                                    />
                                ))}
                            </Box>
                        )}
                    </div>

                    {
                        giftCardData !== 0 ??
                        <div className='d-flex justify-content-center'>
                            <Typography variant="caption" display="block" gutterBottom>Please Select store and Add Gift Card to proceed.</Typography>
                        </div>
                    }

                    <div>
                        <Grid container spacing={2}>
                            {giftCardData.length === 0 ? (
                                <>
                                    {isLoading ? (
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "200px", width: "100%" }}>
                                            <CircularProgress color='primary' />
                                            Loading Giftcards...
                                        </div>
                                    ) : (
                                        <Grid item xs={12}>
                                            <Typography variant="body1" align="center">
                                                No gift card data available.
                                            </Typography>
                                        </Grid>
                                    )}
                                </>

                            ) : (
                                giftCardData.map((gc, index) => (
                                    <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                                        <Card style={{ borderBottom: "1px solid lightgray", borderLeft: "1px solid lightgray", borderRight: "1px solid lightgray", borderTop: "1px solid lightgray", boxShadow: "5px 8px 4px rgba(0, 0, 0, 0.1)" }} >
                                            <CardContent style={{ borderRadius: "10px 10px 0px 0px", backgroundColor: "#C6EDB4", display: "flex", flexDirection: "column", justifyContent: "space-between" }} sx={{ paddingLeft: "10px", paddingTop: 0, paddingBottom: 0 }} >
                                                <div style={{ display: "flex", justifyContent: "start", flexDirection: "column", alignItems: "start" }}>
                                                    <Typography align="center" sx={{ fontWeight: 1000, fontSize: 30 }}>${gc.faceValue}</Typography>
                                                    <Typography align="center" sx={{ letterSpacing: "4px", fontFamily: 'Open Sans, sans-serif' }}>
                                                        GIFT CARD
                                                    </Typography>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "start", paddingBottom: "3px" }}>
                                                    <Typography align="center" sx={{ fontFamily: 'Open Sans, sans-serif', fontSize: 13, letterSpacing: "1px" }}>{gc.validity ? `Valid: ${gc.validity} Days` : `Never Expires`} </Typography>
                                                </div>
                                            </CardContent>


                                            <CardContent style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: "10px", paddingRight: 5, paddingBottom: 10, paddingTop: 5 }}>
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <Typography align="center" sx={{ fontWeight: 1000, fontSize: 14 }}>${gc.actualValue.toFixed(2)}</Typography>
                                                    <Typography style={{ fontStyle: 'italic', fontSize: 12 }} color='primary'>{`(${gc.discount}% off)`}</Typography>
                                                </div>
                                                <Button
                                                    onClick={() => handleAdd(gc)}
                                                    disabled={cartItems.includes(gc)}
                                                    style={{
                                                        opacity: cartItems.includes(gc) ? 0.5 : 1,
                                                        pointerEvents: cartItems.includes(gc) ? 'none' : 'auto',
                                                    }}
                                                    color='primary'

                                                >

                                                    <div style={{ marginRight: "3px" }}>
                                                        Add
                                                    </div>
                                                    <AddCircleOutlineIcon color="primary" />

                                                </Button>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))
                            )}
                        </Grid>
                    </div>


                </div >
            );
        case 1:
            return (

                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <div style={{ marginLeft: "20px", marginBottom: "20px" }}>
                        {
                            cartItems.length > 0 &&
                            <Typography variant="subtitle1" justifyContent='flex-end'>
                                {addedCount} Giftcard Added to cart.
                            </Typography>
                        }
                        {cartItems.length > 0 && (
                            <Box sx={{ display: 'flex', gap: 1, mt: 0.5, mb: 0.5, overflowX: 'auto', maxHeight: 50, maxWidth: 650 }}>
                                {cartItems.map((chip, index) => (
                                    <Chip
                                        key={index}
                                        label={chip.faceValue}
                                        onDelete={() => handleRemoveChip(chip)}
                                        color="primary"
                                    />
                                ))}
                            </Box>
                        )}
                    </div>

                    <Grid container className="cart-details" maxWidth={800} >
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography variant="h6" gutterBottom>
                                Cart Summary
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Typography variant="body1" gutterBottom>
                                Total Amount
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                ${calculatedValues?.totalAmount?.toFixed(2) ?? 0}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Typography variant="body1" gutterBottom>
                                Discounts
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                ${calculatedValues?.discount?.toFixed(2) ?? 0}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12} >
                            <Divider color='primary' className='border border-success' />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Typography variant="body1" gutterBottom>
                                Payable Amount
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                ${calculatedValues?.payableAmount?.toFixed(2) ?? 0}
                            </Typography>
                        </Grid>

                    </Grid>
                </div>
            );
        case 2:
            return (

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12} >
                            <TextField
                                label="Username or Customer ID"
                                placeholder="Enter Username or Customer ID"
                                value={customer}
                                onChange={(event) => {
                                    setCustomer(event?.target?.value);
                                    setError((pre) => ({ ...pre, msg: '', err: false }));
                                }}
                                onKeyDown={handleCustomerIdKeyDown}
                                autoFocus
                                fullWidth
                                error={err.err}
                                helperText={err?.msg?.length ? err?.msg : null}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {/* <IconButton
                                                onClick={() => {
                                                    setScanner(true);
                                                    setShowFields(false);
                                                }}
                                                edge="end"
                                            >
                                                <QrCodeScannerIcon />
                                            </IconButton> */}

                                            <IconButton
                                                onClick={(event) => {
                                                    if (customer.length === 0) {
                                                        setError((prev) => ({ ...prev, err: true, msg: "Please Enter Customer ID or Email.." }))
                                                    }
                                                    event.preventDefault();
                                                    fetchCustomerData();
                                                }}
                                                edge="end"
                                                disabled={customer.length === 0}
                                                sx={{ ml: 1 }}>
                                                <SearchIcon />
                                            </IconButton>

                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Typography variant="caption" display="block" gutterBottom>Please enter username or customer ID and press enter to proceed</Typography>
                            {/* <Typography variant="caption" display="block" gutterBottom>use this: @sai_7 || C1687952202491 </Typography> */}
                        </Grid>

                        {/* {
                            scanner &&
                            <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", paddingBottom: 0 }} >
                                <Typography>QR Code Scanner</Typography>
                                <QrReader
                                    delay={300}
                                    onError={handleError}
                                    onScan={handleScan}
                                    style={{ width: '100%', height: '200px' }}
                                />
                            </Grid>
                        } */}

                        {showFields && (
                            <>

                                <Grid item xs={12} md={6} lg={6} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                    <Typography variant="body2" display="block" gutterBottom>
                                        Customer Name:
                                    </Typography>
                                    <Typography variant="body1" gutterBottom style={{ marginLeft: "10px" }}>
                                        {customerData?.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                    <Typography variant="body2" gutterBottom>
                                        Email:
                                    </Typography>
                                    <Typography variant="body1" style={{ marginLeft: "10px" }} gutterBottom>
                                        {customerData?.email}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                    <Typography variant="body2" gutterBottom>
                                        Customer ID:
                                    </Typography>
                                    <Typography variant="body1" style={{ marginLeft: "10px" }} gutterBottom>
                                        {customerData?.memberId}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </div>

            );
        case 3:
            return (
                <div >
                    <Typography variant="h6" gutterBottom>
                        Payment Confirmation
                    </Typography>
                    <FormControlLabel
                        control={<Checkbox
                            color="primary"
                            checked={step5[0]}
                            onChange={(e) => step5[1](e.target.checked)}
                        />}
                        label="I confirm that the payment has been successfully processed before proceeding."
                    />

                </div>
            );
        case 4:
            return (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

                    {
                        loader &&
                        <>
                            {
                                (creationErr.length > 1) ? null :
                                    <>
                                        <Typography variant="h6" gutterBottom>
                                            Sending Gift Cards
                                        </Typography>
                                        <CircularProgress color='primary' style={{ marginTop: '20px' }} />
                                        <Typography variant="body2" style={{ marginTop: '10px' }}>
                                            Please wait while we send the gift cards to the customers.
                                        </Typography>
                                    </>
                            }
                        </>
                    }

                    {
                        (creationErr.length > 1) &&
                        <>
                            <Typography variant="body1" color='error' gutterBottom>
                                {creationErr.length > 1 ? creationErr : null}
                            </Typography>
                            <Typography variant="body2" style={{ marginTop: '10px' }}>
                                Please click on back, then try the process again..
                            </Typography>
                        </>
                    }

                    {
                        !loader &&
                        <div className='d-flex justify-content-center flex-column align-items-center'>
                            <Typography variant="body1" color='primary' gutterBottom>
                                Giftcards sent successfully to customer
                            </Typography>
                            <Typography variant="body2" style={{ marginTop: '10px' }}>
                                Click next to finish
                            </Typography>
                        </div>
                    }
                </div>
            );
        case 5:
            return (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Typography variant="h6" color='primary' gutterBottom>
                        Transaction Complete!
                    </Typography>
                </div>
            );;
        default:
            return 'Unknown step';
    }
}

export default function SellGiftcardsStepper({ onClose }) {
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const steps = getSteps();
    const { enqueueSnackbar } = useSnackbar();

    // State for Step 1
    const [giftCardData, setGiftCardData] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const [stores, setStores] = useState([]);
    const [storeId, setStoreId] = useState();

    const step1 = [storeId, setStoreId, stores];

    useEffect(() => {
        const valueForStore = JSON.parse(localStorage.getItem("ps"));
        console.log("🚀 ~ file: sellGiftCardStepper.js:553 ~ useEffect ~ valueForStore:", valueForStore)
        if (valueForStore) {
            setStoreId(valueForStore);
        }
    }, [])

    // State for Step 2 
    const [result, setResult] = useState('');
    const [scanner, setScanner] = useState(false);
    // const state = { value: checkout, func: setCheckout }

    // State for Step 3
    const [customerId, setCustomerId] = useState('');
    const [customerData, setCustomerData] = useState({});
    const [customerErr, setCustomerErr] = useState({ err: false, msg: '' })
    const [showFields, setShowFields] = useState(false);



    // for step 4 
    const [loader, setLoader] = useState(true);
    const [creationErr, setCreationErr] = useState('');

    const makeAPICall = async () => {
        try {

            const calculatedValues = {};
            if (cartItems.length) {
                calculatedValues.totalAmount = cartItems.reduce((total, item) => total + item.faceValue, 0);
                calculatedValues.discount = cartItems.reduce((total, item) => total + (item.faceValue - item.actualValue), 0);
                calculatedValues.payableAmount = calculatedValues.totalAmount - calculatedValues.discount.toFixed(2);
            };

            const payload = {
                cartItems: cartItems.map(gc => gc.id),
                customerId: customerData?.memberId,
                storeId: storeId.id,
            };

            const response = await axios.post(endpoints.business.giftcard.sell, payload);

            if (response) {
                setTimeout(() => {
                    setLoader(false);
                    enqueueSnackbar(response.data.msg, { variant: "success" });
                }, 2000)
            };

        } catch (err) {
            setCreationErr(err.error);
            enqueueSnackbar(`${err.error}`, { variant: "error" });
        }
    }

    // Step 5
    const [acknowledgement, setAcknowledgement] = useState(false);
    const step5 = [acknowledgement, setAcknowledgement]

    const handleNext = () => {
        if (activeStep === 3) {
            makeAPICall();
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 4) {
            setLoader(true);
            setCreationErr('');
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    // Fetch data for GC
    const fetchData = async () => {
        try {

            // fetchAPI And save the
            axios.post(endpoints.business.giftcard.list, { offset: 0, limit: 5, status: ['Published'] }).then(({ data }) => {
                // console.log("data",data)
                // const active = data.data.totalItems?.filter(item => (item.status === 'Published')).sort((a, b) => a.faceValue - b.faceValue);
                setGiftCardData(data.data.totalItems);
                setIsLoading(false);
            }).catch(err => console.log(err));
        } catch (error) {
            console.error(error);
        }
    };

    // Fetch data for staffs
    const fetchStoreData = async () => {
        try {
            // fetchAPI And save the
            axios.post(endpoints.business.store.list).then(({ data }) => {
                setStores(data.totalItems);
            }).catch(err => console.log(err));
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        restoreTokenFromStorage();
        fetchData();
        fetchStoreData();
    }, []);

    const isNextCondition = [
        { index: 0, condition: cartItems.length === 0 || !storeId },
        { index: 1, condition: cartItems.length === 0 },
        { index: 2, condition: !showFields },
        { index: 3, condition: !acknowledgement },
        { index: 4, condition: loader },
        { index: 5, condition: false },
        { index: 6, condition: false },
    ];

    const disableConditon = isNextCondition[activeStep].condition;

    return (
        <Box sx={responsiveStyle}>

            {/* Close Button */}
            <div className='d-flex justify-content-end'>
                <Button
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                    color="error"
                    onClick={onClose}
                    variant='outlined'
                >
                    <ClearIcon />
                </Button>
            </div>


            {/* <div style={{ minWidth: "200px", overflowX: "scroll", paddingBottom: "10px", msOverflowStyle: 'none', scrollbarWidth: 'none' }}> */}

            <Stepper activeStep={activeStep} alternativeLabel style={{ minWidth: "200px" }}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {/* </div> */}
            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished!</Typography>
                        <Button onClick={handleReset}>Reset</Button>
                    </div>
                ) : (
                    <div>
                        <div style={{ marginTop: "20px", marginBottom: "10px" }}>{getStepContent(activeStep, giftCardData, cartItems, setCartItems, customerId, setCustomerId, customerErr, setCustomerErr, customerData, setCustomerData, showFields, setShowFields, step5, enqueueSnackbar, step1, loader, creationErr, isLoading, result, setResult, scanner, setScanner)}</div>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                            <Button disabled={(activeStep === 0) || (activeStep === 4 && !loader)} variant="outlined" color='primary' onClick={handleBack}>
                                Back
                            </Button>
                            {activeStep === steps.length - 1 ? (
                                <Button variant="contained" color='primary' onClick={() => {
                                    setAcknowledgement(false);
                                    onClose();
                                }}>
                                    Finish
                                </Button>
                            ) : (
                                <Button variant="contained" color='primary' onClick={handleNext} disabled={disableConditon}>
                                    Next
                                </Button>
                            )}
                        </Box>
                    </div>
                )}
            </div>
        </Box>
    );
}

SellGiftcardsStepper.propTypes = {
    onClose: PropTypes.func.isRequired,
};
