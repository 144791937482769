import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
// import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import GroupIcon from '@mui/icons-material/Group';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DevicesIcon from '@mui/icons-material/Devices';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StoreIcon from '@mui/icons-material/Store';
import PaidIcon from '@mui/icons-material/Paid';
import CollectionsIcon from '@mui/icons-material/Collections';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------
export function useNavData() {
  const { t } = useLocales();

  const isStaff = localStorage.getItem('isStaff') === 'true';


  const data = useMemo(() => {
    if (isStaff) {
      return [
        {
          // subheader: t('Loyali'),
          items: [
            // { title: t('Search Customer'), path: paths.dashboard.staff.searchCustomer, icon: ICONS.calendar },
          ],
        },
      ];
    }

    return [
      {
        subheader: t('management'),
        items: [
          // {
          //   title: t('User'),
          //   path: paths.dashboard.user.root,
          //   icon: ICONS.user,
          //   children: [
          //     { title: t('profile'), path: paths.dashboard.root },
          //   ]
          // },
          { title: t(`Dashboard`), path: paths.dashboard.root, icon: ICONS.dashboard },
          { title: t(`Gift Cards`), path: paths.dashboard.general.gift_Cards, icon: <CardGiftcardIcon /> },
          { title: t(`Offers`), path: paths.dashboard.general.offers, icon: <LocalOfferIcon /> },
          { title: t('Gallery'), path: paths.dashboard.general.gallery, icon: <CollectionsIcon /> },
          { title: t('Reward Plan'), path: paths.dashboard.general.reward_Plan, icon: <EmojiEventsIcon /> },
          { title: t(`Stores`), path: paths.dashboard.general.stores, icon: <StoreIcon /> },
          { title: t(`Staff`), path: paths.dashboard.general.staffs, icon: <GroupIcon /> },
          { title: t(`Devices`), path: paths.dashboard.general.devices, icon: <DevicesIcon /> },
          { title: t(`Subscribers`), path: paths.dashboard.general.subscribers, icon: <GroupIcon /> },
          { title: t('Profile'), path: paths.dashboard.general.profile, icon: <AccountBoxIcon /> },
          { title: t('Transactions'), path: paths.dashboard.general.transactions, icon: <PaidIcon /> },
          { title: t('Billing'), path: paths.dashboard.general.billing, icon: <AttachMoneyIcon /> },
          // { title: t('Chat'), path: paths.dashboard.chat, icon: ICONS.chat },
          // { title: t('Notifications'), path: paths.dashboard.general.notifications, icon: ICONS.lock },
          // { title: t('Reward Customer'), path: paths.dashboard.general.reward_Customer, icon: ICONS.calendar },
        ],
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, isStaff]);

  return data;
}

