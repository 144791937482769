import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
});



axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  getGCAndOffersForDashboard: "/staff/getGCAndOffersForDashboard",
  auth: {
    me: '/api/auth/me',
    login: '/user/loginotp',
    verify: '/user/verifyloginotp',
  },
  business: {
    staff:{
      add: '/business/staff/add',
      update: '/business/staff/update',
      list: '/business/staff/list',
    },
    giftcard: {
      add: '/business/giftcard/add',
      list: '/business/giftcard/list',
      delete: '/business/giftcard/delete',
      update: '/business/giftcard/update',
      publish: '/business/giftcard/publish',
      addandpublish: '/business/giftcard/addandpublish',
      sell: '/business/giftcard/sell',
    },
    offer: {
      add: '/business/offer/add',
      update: '/business/offer/update',
      delete: '/business/offer/delete',
      get: '/business/offer/get',
      list: '/business/offer/list',
      publish: '/business/offer/publish',
      directPublish: '/business/offer/addandpublish'
    },
    gallery: {
      upload: 'business/gallary/image/upload',
      list: 'business/gallary/image/list',
    },
    rewardPlan: {
      details: '/business/rewardplan/details',
      update: '/business/rewardplan/update',
    },
    store: {
      list: '/business/store/list',
      add: '/business/store/add',
      update: '/business/store/update'
    },
    billingPlan: {
      get: '/business/billingplan/details'
    },
    accountInfo: {
      details: 'business/account-info/details',
      update: 'business/account-info/update'
    },
    businessInfo: {
      details: 'business/business-info/details',
      update: 'business/business-info/update'
    },
    owner: {
      updateProfile: 'business/owner-info/update'
    },

    rewardCustomer: {
      add: '/business/rewardtransaction/add',
      update: '/business/rewardtransaction/update',
      details: '/business/rewardtransaction/details',
      verifyOtp: '/business/rewardtransaction/verifyotp',
      resendOtp: '/business/rewardtransaction/resendotp',
      reward: '/business/rewardtransaction/reward',
      delete: '/business/rewardtransaction/delete',
    },
    logo: {
      update: '/business/logo/upload'
    },
    customer: {
      search: '/business/customer/search',
      refund: '/business/customer/refund'
    },
    chat: {
      list: 'business/chat/list',
      addMessage: 'business/chat/addmessage',
      updateReadCount: 'business/chat/updatereadcount',
      // host: 'http://localhost:3000',
      host: 'https://m-d.loyaliapp.com:443', 
    },
    transaction: {
      wallet: '/business/transaction/wallet/list',
      giftcard: '/business/transaction/giftcard/list'
    },
    getTransaction: '/business/transaction/details',
    stats: '/business/stats',
  },

  staff: {
    giftcard: {
      sell: '/staff/giftcard/sell',
    },
    store: {
      list: '/staff/store/list'
    },
    customer: {
      search: '/staff/customer/search',
      refund: '/staff/customer/refund'
    },
    rewardCustomer: {
      add: '/staff/rewardtransaction/add',
      update: '/staff/rewardtransaction/update',
      details: '/staff/rewardtransaction/details',
      verifyOtp: '/staff/rewardtransaction/verifyotp',
      resendOtp: '/staff/rewardtransaction/resendotp',
      reward: '/staff/rewardtransaction/reward',
      delete: '/staff/rewardtransaction/delete',
    },
    rewardPlan: {
      details: '/staff/rewardplan/details',
    },
  },

  devices: {
    get: '/business/device/details',
    list: '/business/device/list',
    add: '/business/device/add',
    update: '/business/device/update',
    delete: '/business/device/delete'
  },
  subscibers: {
    get: '/business/subscription/details',
    list: '/business/subscription/list',
  },
  transactions: {
    get: '/business/transaction/details',
    list: '/business/transaction/list',
  }
};
